export const newAuthTypeOptions = [
    // { value: "none", label: "Anonymous" },
    // { value: "local", label: "Local" },
    { value: "ad", label: "Active Directory" },
    { value: "oauth", label: "OAuth 2.0" },
    { value: "database", label: "Use database login" },
    { value: "msentra", label: "Microsoft Entra" },
];
export function getAuthTypeLabel(authType) {
    const option = newAuthTypeOptions.find((option) => option.value === authType);
    return option ? option.label : '';
}
