import * as AdminConnectionsTab from './AdminConnectionsTab.svelte';
import * as AdminUsersTab from './AdminUsersTab.svelte';
import * as AdminRolesTab from './AdminRolesTab.svelte';
import * as AdminAuthTab from './AdminAuthTab.svelte';

export default {
  AdminConnectionsTab,
  AdminUsersTab,
  AdminRolesTab,
  AdminAuthTab,
};
