<script lang="ts">
  import { testSubPermission } from 'dbgate-tools';

  export let permission;
  export let basePermissions;
  export let label;

  export let onSetPermission;

  export let loadedPermissions;
  export let insertedPermissions;
  export let deletedPermissions;

  $: currentPermissionSet = [...loadedPermissions.filter(x => !deletedPermissions.includes(x)), ...insertedPermissions];
  $: baseThisPermissionValue = testSubPermission(permission, currentPermissionSet, false);

  // permission value from base
  $: basePermissionValue =
    baseThisPermissionValue == null ? testSubPermission(permission, basePermissions) : baseThisPermissionValue;

  $: isPermission = currentPermissionSet.includes(permission)
    ? true
    : currentPermissionSet.includes('~' + permission)
      ? false
      : basePermissionValue;
  // $: isPermission = isThisPermission ?
  //   (loadedPermissions.includes(permission) && !deletedPermissions.includes(permission)) ||
  //   insertedPermissions.includes(permission);

  $: isInherited =
    basePermissionValue != null &&
    !currentPermissionSet.includes(permission) &&
    !currentPermissionSet.includes('~' + permission);

  function getNextValue() {
    if (isInherited) {
      return true;
    }
    if (isPermission) {
      return false;
    }
    return null;
  }
</script>

<div
  class="wrapper"
  on:click|preventDefault|stopPropagation={() => {
    onSetPermission(permission, getNextValue());
  }}
>
  <div class="checkbox" {...$$restProps} class:checked={!!isPermission} class:isInherited />
  <div class="label">
    {label}
  </div>
</div>

<style>
  .wrapper {
    display: flex;
    align-items: center;
    margin: 4px;
    cursor: pointer;
  }

  .label {
    user-select: none;
  }

  .checkbox {
    width: 14px !important;
    height: 14px !important;
    margin: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: 1px solid var(--theme-border);
    box-shadow: none;
    font-size: 0.8em;
    text-align: center;
    line-height: 1em;
    background: var(--theme-bg-0);
  }

  .checked:after {
    content: '✔';
    color: var(--theme-font-1);
    font-weight: bold;
  }

  .isInherited {
    background: var(--theme-bg-2) !important;
  }
</style>
