<script>
  import { writable } from 'svelte/store';

  import FormFieldTemplateLarge from '../forms/FormFieldTemplateLarge.svelte';
  import FormSelectField from '../forms/FormSelectField.svelte';
  import FormProviderCore from '../forms/FormProviderCore.svelte';
  import ErrorInfo from '../elements/ErrorInfo.svelte';
  import FormTextField from '../forms/FormTextField.svelte';
  import FormPasswordField from '../forms/FormPasswordField.svelte';
  import ToolStripContainer from '../buttons/ToolStripContainer.svelte';
  import ToolStripButton from '../buttons/ToolStripButton.svelte';
  import { apiCall, disableApi, useApiCall } from '../utility/api';
  import { onMount } from 'svelte';
  import { showSnackbarSuccess } from '../utility/snackbar';
  import { isAdminPage } from '../utility/pageDefs';
  import Link from '../elements/Link.svelte';
  import FormCheckboxField from '../forms/FormCheckboxField.svelte';
  import hasPermission from '../utility/hasPermission';
  import stableStringify from 'json-stable-stringify';
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import CollapseButton from '../datagrid/CollapseButton.svelte';
  import { getAuthTypeLabel } from './authAdminTools';
  import { showModal } from '../modals/modalTools';
  import InputTextModal from '../modals/InputTextModal.svelte';

  export let authMethod;
  export let onChange;
  export let onDelete;

  const values = writable({ ...authMethod });

  $: {
    if (stableStringify($values) != stableStringify(authMethod)) {
      onChange($values);
    }
  }

  $: authType = $values?.type;
</script>

<FormProviderCore {values} template={FormFieldTemplateLarge}>
  <div class="header">
    <div class="title-line">
      <div class="name-container">
        <CollapseButton
          vertical
          collapsed={$values.isCollapsed}
          on:click={() => {
            $values.isCollapsed = !$values.isCollapsed;
          }}
        />
        <div class="name-type">
          <div class="name" class:disabled={$values.isDisabled}>{$values.name}</div>
          {#if !$values.isDisabled && getAuthTypeLabel($values.type) != $values.name}
            <div class="type">{getAuthTypeLabel($values.type)}</div>
          {/if}
          {#if $values.isDisabled}
            <div class="type">(disabled)</div>
          {/if}
        </div>
      </div>
      <div>
        {#if $values.isDisabled}
          <FormStyledButton
            value="Enable"
            on:click={() => {
              $values.isDisabled = false;
            }}
          />
        {:else}
          <FormStyledButton
            value="Disable"
            on:click={() => {
              $values.isDisabled = true;
            }}
          />
        {/if}
        <FormStyledButton value="Delete" disabled={$values.id < 0} on:click={() => onDelete($values.amoid)} />
        <FormStyledButton
          value="Rename"
          on:click={() => {
            showModal(InputTextModal, {
              value: $values.name,
              label: 'New authentication name',
              header: 'Rename authentication method',
              onConfirm: newName => {
                $values.name = newName;
              },
            });
          }}
        />
      </div>
    </div>
  </div>
  {#if !$values.isCollapsed}
    <div class="collapse-wrapper">
      {#if (authType ?? 'none') == 'none'}
        <div class="info-wrap">
          <ErrorInfo message="Your DbGate server will be available without authentication" icon="img info" alignTop />
        </div>
      {/if}

      {#if authType == 'local'}
        <div class="info-wrap">
          <ErrorInfo message="Define logins and passwords in Users administration" icon="img info" alignTop />
        </div>
      {/if}

      {#if authType == 'database'}
        <div class="info-wrap">
          <ErrorInfo
            message="Forward credentials to database server. User has to choose database connection from all defined connections at first. If DB uses SSO (eg. Microsoft Entra ID), user is redirected to database login page. If you have connections with password stored in connection, user is logged in automatically after choosing them."
            icon="img info"
            alignTop
          />
        </div>
        <FormCheckboxField label="Allow only defined logins" name="dbloginOnlyDefinedLogins" />
      {/if}

      {#if authType == 'ad'}
        <FormTextField label="Active Directory URL" name="adUrl" />
        <FormTextField label="Base DN" name="adBaseDN" />
        <FormTextField label="AD User name" name="adLogin" />
        <FormPasswordField label="AD Password" name="adPassword" />
        <FormCheckboxField label="Allow only defined logins" name="adOnlyDefinedLogins" />
      {/if}

      {#if authType == 'oauth'}
        <FormTextField label="OAuth auth endpoint" name="oauthAuth" />
        <FormTextField label="OAuth token endpoint" name="oauthToken" />
        <FormTextField label="OAuth logout endpoint" name="oauthLogout" />
        <FormTextField label="OAuth scope" name="oauthScope" />
        <FormTextField label="OAuth client" name="oauthClient" />
        <FormTextField label="OAuth client secret" name="oauthClientSecret" />
        <FormTextField label="OAuth login field" name="oauthLoginField" />
        <FormCheckboxField label="Allow only defined logins" name="oauthOnlyDefinedLogins" />
        <FormTextField label="OAuth group field" name="oauthGroupField" />
        <FormCheckboxField label="Allow only defined groups (searched in roles)" name="oauthOnlyDefinedGroups" />
      {/if}

      {#if authType == 'msentra'}
        <FormCheckboxField label="Allow only defined logins (find by email)" name="msentraOnlyDefinedLogins" />
      {/if}
    </div>
  {/if}
</FormProviderCore>

<style>
  .header {
    display: block;
    border: 1px solid var(--theme-border);
    border-radius: 5px;
    margin: var(--dim-large-form-margin);
    background-color: var(--theme-bg-2);
  }

  .title-line {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
  }

  .name {
    font-size: 1.2em;
    margin-left: 10px;
  }

  .name.disabled {
    color: var(--theme-font-3);
  }

  .name-container {
    display: flex;
    align-items: center;
  }

  .type {
    color: var(--theme-font-3);
    margin-left: 10px;
  }

  .name-type {
    display: flex;
    align-items: baseline;
  }

  .info-wrap {
    margin-left: var(--dim-large-form-margin);
  }

  .collapse-wrapper {
    margin-left: var(--dim-large-form-margin);
    border-left: 1px solid var(--theme-border);
  }
</style>
