<script lang="ts">
  import { CustomGridDisplay, CustomGridColumn, reloadDataCacheFunc } from 'dbgate-datalib';
  import { extendDatabaseInfoFromApps, findEngineDriver } from 'dbgate-tools';
  import _ from 'lodash';
  import { extensions } from '../stores';

  import {
    useConnectionInfo,
    useDatabaseInfo,
    useDatabaseServerVersion,
    useUsedApps,
  } from '../utility/metadataLoaders';

  import DataGrid from '../datagrid/DataGrid.svelte';
  import SqlDataGridCore from '../datagrid/SqlDataGridCore.svelte';
  import { Condition } from 'dbgate-sqltree';

  export let conid;
  export let database;
  export let schemaName;
  export let pureName;

  export let changeSetState;
  export let dispatchChangeSet;

  export let config;
  export let setConfig;

  export let cache;
  export let setCache;
  export let multipleGridsOnTab = false;

  export let columns: CustomGridColumn[];
  export let isReadOnly = false;
  export let additionalCondition: Condition = null;

  $: connection = useConnectionInfo({ conid });
  $: dbinfo = useDatabaseInfo({ conid, database });
  $: serverVersion = useDatabaseServerVersion({ conid, database });
  $: apps = useUsedApps();
  $: extendedDbInfo = extendDatabaseInfoFromApps($dbinfo, $apps);

  // $: console.log('additionalCondition', additionalCondition);

  $: display =
    connection && $serverVersion
      ? new CustomGridDisplay(
          { schemaName, pureName },
          columns,
          findEngineDriver($connection, $extensions),
          config,
          setConfig,
          cache,
          setCache,
          extendedDbInfo,
          $serverVersion,
          isReadOnly,
          additionalCondition
        )
      : null;

  $: {
    additionalCondition;
    if (setCache) {
      setCache(reloadDataCacheFunc);
    }
  }
</script>

<DataGrid {...$$props} gridCoreComponent={SqlDataGridCore} {display} hasMultiColumnFilter hideGridLeftColumn />
