<script lang="ts" context="module">
  export const matchingProps = [];
</script>

<script lang="ts">
  import { writable } from 'svelte/store';
  import AdminGridWithDetail from '../admin/AdminGridWithDetail.svelte';
  import { CustomGridColumn } from 'dbgate-datalib';
  import TabControl from '../elements/TabControl.svelte';
  import AdminSubsetChooser from '../admin/AdminSubsetChooser.svelte';
  import { adminConnectionColumns, adminRoleColumns, adminUserColumns } from '../admin/adminGridColumns';
  import PermissionsChooser from '../admin/PermissionsChooser.svelte';
  import { apiCall } from '../utility/api';
  import { getPredefinedPermissions } from 'dbgate-tools';
  import { onMount } from 'svelte';
  import hasPermission from '../utility/hasPermission';
  import ErrorInfo from '../elements/ErrorInfo.svelte';

  const selectedObjectStore = writable({});

  let loggedUserBasePermissions = [];
  export let tabid;

  async function loadBasePermissions() {
    const permissions = await apiCall('storage/read-permissions', {
      tableName: 'role_permissions',
      filterColumn: 'role_id',
      filterValue: -2, // logged user
    });
    loggedUserBasePermissions = [...getPredefinedPermissions('logged-user'), ...permissions.map(x => x.permission)];
  }
  onMount(() => {
    loadBasePermissions();
  });

  $: basePermissions = getPredefinedPermissions($selectedObjectStore?.['name']) ?? loggedUserBasePermissions;
</script>

{#if hasPermission('admin/roles')}
  <AdminGridWithDetail
    columns={adminRoleColumns}
    {tabid}
    fireEvents={['admin-roles-changed']}
    pureAdminTableName="roles"
    {...$$restProps}
    {selectedObjectStore}
    icon="icon role"
    newButtonTitle="New role"
    getNewObject={() => ({})}
  >
    <svelte:fragment slot="detail" let:changeSetState let:dispatchChangeSet let:saveKey>
      <TabControl
        tabs={[
          { label: 'Connections', slot: 1 },
          { label: 'Users', slot: 2 },
          { label: 'Permissions', slot: 3 },
        ]}
      >
        <svelte:fragment slot="1">
          <div>
            <AdminSubsetChooser
              mainTableName="connections"
              mappingTableName="role_connections"
              columns={adminConnectionColumns}
              primaryValue={$selectedObjectStore?.['id']}
              primaryColumn="role_id"
              referencedColumn="connection_id"
              {changeSetState}
              {dispatchChangeSet}
              reloadOnEvent="admin-connections-changed"
            />
          </div>
        </svelte:fragment>
        <svelte:fragment slot="2">
          <div>
            <AdminSubsetChooser
              mainTableName="users"
              mappingTableName="user_roles"
              primaryValue={$selectedObjectStore?.['id']}
              primaryColumn="role_id"
              referencedColumn="user_id"
              {changeSetState}
              {dispatchChangeSet}
              columns={adminUserColumns}
              reloadOnEvent="admin-users-changed"
            />
          </div>
        </svelte:fragment>
        <svelte:fragment slot="3">
          <PermissionsChooser
            permissionsTableName="role_permissions"
            filterValue={$selectedObjectStore?.['id']}
            filterColumn="role_id"
            {changeSetState}
            {dispatchChangeSet}
            {saveKey}
            {basePermissions}
          />
        </svelte:fragment>
      </TabControl>
    </svelte:fragment>
  </AdminGridWithDetail>
{:else}
  <ErrorInfo message="You don't have permission to configure roles" icon="img warn" alignTop />
{/if}
