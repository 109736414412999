<script lang="ts">
  import { changeSetInsertDocuments, deleteChangeSetRows } from 'dbgate-datalib';
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import AdminStorageDataGrid from './AdminStorageDataGrid.svelte';
  import _ from 'lodash';

  export let mainTableName;
  export let mappingTableName;
  export let columns;
  export let changeSetState;
  export let dispatchChangeSet;
  export let primaryValue;
  export let primaryColumn;
  export let referencedColumn;
  export let reloadOnEvent = null;

  let publishedCellsAll = [];
  let publishedCellsSelected = [];

  function handleAddSingle() {
    const selectedFromAll = publishedCellsAll;
    const selectedIds = _.uniq(selectedFromAll.map(x => x.rowData.id));

    dispatchChangeSet({
      type: 'set',
      value: changeSetInsertDocuments(
        changeSetState.value,
        selectedIds.map(selectedId => ({
          [primaryColumn]: primaryValue,
          [referencedColumn]: selectedId,
        })),
        { pureName: mappingTableName },
        [primaryColumn, referencedColumn]
      ),
    });
  }

  function handleAddAll() {}

  function handleRemoveSingle() {
    const selectedFromSelected = publishedCellsSelected;
    const selectedIds = _.uniq(selectedFromSelected.map(x => x.rowData.id));

    let changeSet = changeSetState.value;
    for (const id of selectedIds) {
      changeSet = deleteChangeSetRows(changeSet, {
        pureName: mappingTableName,
        schemaName: null,
        condition: {
          [primaryColumn]: primaryValue,
          [referencedColumn]: id,
        },
      });
    }
    dispatchChangeSet({
      type: 'set',
      value: changeSet,
    });
  }

  function handleRemoveAll() {}

  $: existsAdditionalCondition = {
    conditionType: 'exists',
    subQuery: {
      commandType: 'select',
      selectAll: true,
      from: {
        name: {
          pureName: mappingTableName,
        },
      },
      where: {
        conditionType: 'and',
        conditions: [
          {
            conditionType: 'binary',
            operator: '=',
            left: {
              exprType: 'column',
              columnName: 'id',
              source: { alias: 'basetbl' },
            },
            right: {
              exprType: 'column',
              columnName: referencedColumn,
              source: { name: { pureName: mappingTableName } },
            },
          },
          {
            conditionType: 'binary',
            operator: '=',
            left: {
              exprType: 'column',
              columnName: primaryColumn,
              source: { name: { pureName: mappingTableName } },
            },
            right: {
              exprType: 'value',
              value: primaryValue,
            },
          },
        ],
      },
    },
  };

  $: insertedIds = changeSetState.value.inserts
    .filter(x => x.pureName == mappingTableName)
    .map(x => x.fields[referencedColumn]);

  $: removedIds = changeSetState.value.deletes
    .filter(x => x.pureName == mappingTableName)
    .map(x => x.condition[referencedColumn]);

  $: inAdditionalCondition =
    insertedIds.length > 0
      ? {
          conditionType: 'in',
          expr: {
            exprType: 'column',
            columnName: 'id',
            source: { pureName: mainTableName },
          },
          values: insertedIds,
        }
      : null;

  $: notIninAdditionalCondition =
    removedIds.length > 0
      ? {
          conditionType: 'notIn',
          expr: {
            exprType: 'column',
            columnName: 'id',
            source: { pureName: mainTableName },
          },
          values: removedIds,
        }
      : null;

  $: additionalConditionWithIn = inAdditionalCondition
    ? {
        conditionType: 'or',
        conditions: [existsAdditionalCondition, inAdditionalCondition],
      }
    : existsAdditionalCondition;

  $: additionalCondition = notIninAdditionalCondition
    ? {
        conditionType: 'and',
        conditions: [additionalConditionWithIn, notIninAdditionalCondition],
      }
    : additionalConditionWithIn;

  // $: console.log('insertedIds', insertedIds);
  // $: console.log('primaryValue', primaryValue);
  // $: console.log('additionalCondition', additionalCondition);
  // $: console.log('existsAdditionalCondition', existsAdditionalCondition);
</script>

<div class="wrapper">
  <div class="column">
    <div class="heading">All items</div>
    <div class="table">
      <AdminStorageDataGrid
        pureName={mainTableName}
        {columns}
        isReadOnly
        onPublishedCellsChanged={value => {
          publishedCellsAll = value;
        }}
        {reloadOnEvent}
        multipleGridsOnTab
      />
    </div>
  </div>

  <div class="buttons">
    <FormStyledButton value=">" on:click={handleAddSingle} />
    <!-- <FormStyledButton value=">>" on:click={handleAddAll} /> -->
    <FormStyledButton value="<" on:click={handleRemoveSingle} />
    <!-- <FormStyledButton value="<<" on:click={handleRemoveAll} /> -->
  </div>

  <div class="column">
    <div class="heading">Selected items</div>
    <div class="table">
      <AdminStorageDataGrid
        pureName={mainTableName}
        {columns}
        isReadOnly
        onPublishedCellsChanged={value => {
          publishedCellsSelected = value;
        }}
        {additionalCondition}
        {reloadOnEvent}
        multipleGridsOnTab
      />
    </div>
  </div>
</div>

<style>
  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  .table {
    flex: 1;
    position: relative;
  }

  .buttons {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--theme-border);
    border-right: 1px solid var(--theme-border);
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .heading {
    background-color: var(--theme-bg-3);
    padding: 5px;
    font-weight: bold;
  }
</style>
