<script lang="ts" context="module">
  export const matchingProps = [];
</script>

<script lang="ts">
  import { writable } from 'svelte/store';
  import AdminGridWithDetail from '../admin/AdminGridWithDetail.svelte';
  import ConnectionTab from './ConnectionTab.svelte';
  import { getCurrentConfig } from '../stores';
  import { adminConnectionFullColumns } from '../admin/adminGridColumns';
  import ErrorInfo from '../elements/ErrorInfo.svelte';
  import hasPermission from '../utility/hasPermission';
  import uuidv1 from 'uuid/v1';

  export let tabid;

  const selectedObjectStore = writable({});
  let domConnections;
</script>

{#if hasPermission('admin/connections')}
  <AdminGridWithDetail
    pureAdminTableName="connections"
    fireEvents={['admin-connections-changed']}
    {tabid}
    columns={adminConnectionFullColumns}
    {...$$restProps}
    {selectedObjectStore}
    icon="icon connection"
    newButtonTitle="New connection"
    getNewObject={() => ({
      server: getCurrentConfig().isDocker ? 'dockerhost' : 'localhost',
      engine: '',
      conid: uuidv1(),
    })}
    normalizeFormValues={values => {
      let res = {
        ...values,
        useDatabaseUrl: values.useDatabaseUrl ? 1 : 0,
        isReadOnly: values.isReadOnly ? 1 : 0,
        trustServerCertificate: values.trustServerCertificate ? 1 : 0,
        singleDatabase: values.singleDatabase ? 1 : 0,
        useSshTunnel: values.useSshTunnel ? 1 : 0,
        useSsl: values.useSsl ? 1 : 0,
        sslRejectUnauthorized: values.sslRejectUnauthorized ? 1 : 0,
      };
      if (domConnections) {
        res = domConnections.changeConnectionBeforeSave(res);
      }
      return res;
    }}
    splitHorizontal
  >
    <svelte:fragment slot="detail">
      <div class="detail-wrap">
        <ConnectionTab bind:this={domConnections} connectionStore={selectedObjectStore} onlyTestButton />
      </div>
    </svelte:fragment>
  </AdminGridWithDetail>
{:else}
  <ErrorInfo message="You don't have permission to manage connections" />
{/if}

<style>
  .detail-wrap {
    display: flex;
    flex: 1;
    border-top: 1px solid var(--theme-border);
  }
</style>
