export const adminConnectionFullColumns = [
    { columnName: 'id', columnLabel: 'ID', isPrimaryKey: true },
    { columnName: 'conid', columnLabel: 'Connection ID' },
    { columnName: 'displayName', columnLabel: 'Display Name' },
    { columnName: 'engine', columnLabel: 'Engine' },
    { columnName: 'server', columnLabel: 'Server' },
    { columnName: 'user', columnLabel: 'User' },
];
export const adminConnectionColumns = [
    { columnName: 'id', columnLabel: 'ID', isPrimaryKey: true },
    { columnName: 'displayName', columnLabel: 'Display name' },
    { columnName: 'server', columnLabel: 'Server' },
    { columnName: 'engine', columnLabel: 'Engine' },
];
export const adminRoleColumns = [
    { columnName: 'id', columnLabel: 'ID', isPrimaryKey: true },
    { columnName: 'name', columnLabel: 'Name' },
];
export const adminUserColumns = [
    { columnName: 'id', columnLabel: 'ID', isPrimaryKey: true },
    { columnName: 'login', columnLabel: 'Login' },
    { columnName: 'password', columnLabel: 'Password' },
    { columnName: 'email', columnLabel: 'E-mail' },
];
