<script lang="ts">
  import FontIcon from '../icons/FontIcon.svelte';
  import WidgetTitle from '../widgets/WidgetTitle.svelte';
  import openNewTab from '../utility/openNewTab';
  import hasPermission from '../utility/hasPermission';
  import { activeTab } from '../stores';
</script>

<div class="wrapper">
  <WidgetTitle>Administration</WidgetTitle>
  <div class="list">
    {#if hasPermission('admin/auth')}
      <div
        class="item"
        class:selected={$activeTab?.tabComponent == 'AdminAuthTab'}
        on:click={() => {
          openNewTab({
            title: 'Authentication',
            icon: 'img auth',
            tabComponent: 'AdminAuthTab',
            props: {},
          });
        }}
      >
        <FontIcon icon="icon auth" padRight />
        Authentication
      </div>
    {/if}

    {#if hasPermission('admin/connections')}
      <div
        class="item"
        class:selected={$activeTab?.tabComponent == 'AdminConnectionsTab'}
        on:click={() => {
          openNewTab({
            title: 'Connections',
            icon: 'img connection',
            tabComponent: 'AdminConnectionsTab',
            props: {},
          });
        }}
      >
        <FontIcon icon="icon connection" padRight />
        Connections
      </div>
    {/if}

    {#if hasPermission('admin/users')}
      <div
        class="item"
        class:selected={$activeTab?.tabComponent == 'AdminUsersTab'}
        on:click={() => {
          openNewTab({
            title: 'Users',
            icon: 'img users',
            tabComponent: 'AdminUsersTab',
            props: {},
          });
        }}
      >
        <FontIcon icon="icon users" padRight />
        Users
      </div>
    {/if}

    {#if hasPermission('admin/roles')}
      <div
        class="item"
        class:selected={$activeTab?.tabComponent == 'AdminRolesTab'}
        on:click={() => {
          openNewTab({
            title: 'Roles',
            icon: 'img role',
            tabComponent: 'AdminRolesTab',
            props: {},
          });
        }}
      >
        <FontIcon icon="icon role" padRight />
        Roles
      </div>
    {/if}
  </div>
</div>

<style>
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--theme-font-1);
    font-size: 12pt;
  }
  .item:hover {
    background: var(--theme-bg-2);
  }
  .item.selected {
    background: var(--theme-bg-3);
  }

  .list {
    flex: 1;
  }
</style>
