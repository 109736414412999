<script lang="ts" context="module">
  export const matchingProps = [];
</script>

<script>
  import FormFieldTemplateLarge from '../forms/FormFieldTemplateLarge.svelte';
  import ErrorInfo from '../elements/ErrorInfo.svelte';
  import ToolStripContainer from '../buttons/ToolStripContainer.svelte';
  import ToolStripButton from '../buttons/ToolStripButton.svelte';
  import { apiCall } from '../utility/api';
  import { onMount } from 'svelte';
  import { showSnackbarSuccess } from '../utility/snackbar';
  import { isAdminPage } from '../utility/pageDefs';
  import Link from '../elements/Link.svelte';
  import hasPermission from '../utility/hasPermission';
  import AdminAuthForm from '../admin/AdminAuthForm.svelte';
  import SelectField from '../forms/SelectField.svelte';
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import uuidv1 from 'uuid/v1';
  import { getAuthTypeLabel, newAuthTypeOptions } from '../admin/authAdminTools';
  import ToolStripDropDownButton from '../buttons/ToolStripDropDownButton.svelte';
  import { markTabSaved, markTabUnsaved } from '../utility/common';
  import { openedTabs } from '../stores';

  let authMethods = [];
  export let tabid;
  export let unsaved;
  // $: unsaved = $openedTabs.find(x => x.tabid == tabid)?.['unsaved'];

  onMount(async () => {
    authMethods = await apiCall('storage/read-auth-config');
    markTabSaved(tabid);
  });

  async function handleSave() {
    await apiCall('storage/write-auth-config', { authMethods });
    if (isAdminPage()) {
      showSnackbarSuccess('Saved to database');
    } else {
      location.reload();
    }
    markTabSaved(tabid);
  }

  function getAddAuthTypeMenu() {
    return newAuthTypeOptions.map(x => ({
      label: x.label,
      onClick: () => {
        authMethods = [
          ...authMethods,
          {
            type: x.value,
            amoid: uuidv1(),
            name: x.label,
          },
        ];
        markTabUnsaved(tabid);
      },
    }));
  }
</script>

{#if hasPermission('admin/auth')}
  <ToolStripContainer scrollContent>
    {#if !isAdminPage()}
      <ErrorInfo
        message="Please use admin page for configure authentization. After save from current location, new auth settings will be applied"
        icon="img warn"
        alignTop
      />
      <div class="ml-2">
        <Link href="/admin.html">Go to admin page</Link>
      </div>
    {/if}

    <div class="container">
      {#each authMethods as authMethod}
        <AdminAuthForm
          authMethod={{ ...authMethod }}
          onChange={m => {
            authMethods = authMethods.map(x => (x.amoid == authMethod.amoid ? m : x));
            markTabUnsaved(tabid);
          }}
          onDelete={amoid => {
            authMethods = authMethods.filter(x => amoid != x.amoid);
            markTabUnsaved(tabid);
          }}
        />
      {/each}
    </div>

    <svelte:fragment slot="toolstrip">
      <ToolStripButton icon="icon save" on:click={handleSave} disabled={!unsaved}>Save</ToolStripButton>
      <ToolStripDropDownButton menu={getAddAuthTypeMenu} label="Add authentication" icon="icon add" />
    </svelte:fragment>
  </ToolStripContainer>
{:else}
  <ErrorInfo message="You don't have permission to configure authentication" icon="img warn" alignTop />
{/if}

<style>
  .container {
    max-width: 800px;
  }
</style>
