<script lang="ts">
  import { writable } from 'svelte/store';
  import { useConfig } from '../utility/metadataLoaders';
  import CustomDataGrid from './CustomDataGrid.svelte';
  import { createGridCache, reloadDataCacheFunc } from 'dbgate-datalib';
  import useGridConfig from '../utility/useGridConfig';
  import { apiOff, apiOn } from '../utility/api';
  import useEffect from '../utility/useEffect';

  $: mainConfig = useConfig();

  export let reloadOnEvent = null;

  const cache = writable(createGridCache());
  const config = useGridConfig('__admin_grid');

  export function reload() {
    cache.update(reloadDataCacheFunc);
  }

  $: effect = useEffect(() => registerReloadOnEvent(reloadOnEvent));

  function registerReloadOnEvent(eventName) {
    if (eventName) {
      apiOn(eventName, reload);
      return () => {
        apiOff(eventName, reload);
      };
    } else {
      return () => {};
    }
  }

  $: $effect;
</script>

<CustomDataGrid
  {...$$props}
  database={$mainConfig?.storageDatabase}
  conid="__storage"
  config={$config}
  setConfig={config.update}
  cache={$cache}
  setCache={cache.update}
/>
